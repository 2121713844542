import React, { useRef } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Hero from './components/HeroSection';
import Services from './components/ServicesSection';
import Technologies from './components/TechnologiesSection';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import './App.css';

function App() {

  const aboutRef = useRef(null);

  const scrollToAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Navbar />
      <Hero onLearnMoreClick={scrollToAbout} />
      <Services />
      <Technologies />
      <AboutUs ref={aboutRef} />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;

import React from 'react';
import '../styles/Footer.css';
import { FaLinkedin, FaInstagram, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <h3>Soft Wizzards</h3>
          <p>The Magician For Your Softwares</p>
        </div>
        <div className="footer-links">
          <a href="#home">Home</a>
          <a href="#services">Services</a>
          <a href="#technologies">Technologies</a>
          <a href="#about">About</a>
          <a href="#contact">Contact</a>
        </div>
        <div className="footer-socials">
          <a href="https://wa.me/+919355259260" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
          <a href="https://www.linkedin.com/company/soft-wizzards" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
          <a href="https://www.instagram.com/softwizzards/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Soft Wizzards - All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import "../styles/Home.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Hero = ({ onLearnMoreClick }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    services: "",
    budget: "",
    time: "",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormErrors({});
  };

  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name.trim()) {
      errors.name = "Name is required.";
    }

    if (!phone || phone.length < 10) {
      errors.phone = "Valid phone number is required.";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Please enter a valid email address.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/softwizzards/v1/save-data`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            mobile: phone,
            email: formData.email,
            message: formData.message,
            service: formData.services,
            budget: formData.budget,
            timeframe: formData.time,
          }),
        }
      );

      if (response.ok) {
        setShowSuccessModal(true);
        setFormData({
          name: "",
          email: "",
          message: "",
          services: "",
          budget: "",
          time: "",
        });
        setPhone("");
        setFormErrors({});
      } else {
        throw new Error("Failed to send data");
      }
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const closeSuccessModal = () => setShowSuccessModal(false);
  const closeErrorModal = () => setShowErrorModal(false);

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <section className={`hero ${isVisible ? "visible" : ""}`} id="home">
      <div className="content">
        <h1>
          Welcome
          <br />
          to
          <br />
          <span className="highlight">Soft Wizzards</span>
        </h1>
        <p className="tagline">
          The Magician For Your Softwares. <br />
        </p>
        <div className="cta-buttons">
          <button className="btn-primary" onClick={openModal}>
            Request free quote
          </button>
          <button className="btn-secondary" onClick={onLearnMoreClick}>
            Learn More
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={closeModal}>
              ×
            </button>

            <h2>Request a Free Quote</h2>
            {isLoading ? (
              <div className="loader">
                <div className="spinner"></div>
                <p>Sending your request...</p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} noValidate>
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Your Name"
                  required
                />
                {formErrors.name && (
                  <p className="error-text">{formErrors.name}</p>
                )}

                <label htmlFor="phone">Mobile:</label>
                <PhoneInput
                  country="in"
                  value={phone}
                  onChange={(value) => setPhone(value)}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                  enableSearch={true}
                  disableCountryCode={false}
                  disableDropdown={false}
                  placeholder="Enter mobile number"
                />
                {formErrors.phone && (
                  <p className="error-text">{formErrors.phone}</p>
                )}

                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Your Email"
                  required
                />
                {formErrors.email && (
                  <p className="error-text">{formErrors.email}</p>
                )}

                <label htmlFor="service">Select a Service *</label>
                <select
                  id="service"
                  name="services"
                  value={formData.services} // Correctly tied to state
                  onChange={handleSelectChange} // Updates state on change
                  required
                >
                  <option value="" disabled>
                    Select a service
                  </option>
                  <option value="Web Development">Web Development</option>
                  <option value="Mobile App Development">
                    Mobile App Development
                  </option>
                  <option value="Cloud Computing">Cloud Computing</option>
                  <option value="Search Engine Optimization">
                    Search Engine Optimization
                  </option>
                  <option value="Social Media Marketing">
                    Social Media Marketing
                  </option>
                  <option value="Hosting Registration">
                    Hosting Registration
                  </option>
                  <option value="Domain Registration">
                    Domain Registration
                  </option>
                </select>
                {formErrors.services && (
                  <p className="error-text">{formErrors.services}</p>
                )}

                <label htmlFor="budget">Budget Range</label>
                <select
                  id="budget"
                  name="budget"
                  value={formData.budget} // Correctly tied to state
                  onChange={handleSelectChange} // Updates state on change
                  required
                >
                  <option value="" disabled>
                    Select budget range
                  </option>
                  <option value="under-20000">&lt; ₹20,000</option>
                  <option value="20000-100000">₹20,000 - ₹1,00,000</option>
                  <option value="above-100000">&gt; ₹1,00,000</option>
                </select>
                {formErrors.budget && (
                  <p className="error-text">{formErrors.budget}</p>
                )}

                <label htmlFor="time">Timeframe</label>
                <select
                  id="time"
                  name="time"
                  value={formData.time} // Correctly tied to state
                  onChange={handleSelectChange} // Updates state on change
                  required
                >
                  <option value="" disabled>
                    Select Timeframe
                  </option>
                  <option value="1 Week">1 Week</option>
                  <option value="2 Week">2 Week</option>
                  <option value="1 Month">1 Month</option>
                  <option value="More than 1 Month">More than 1 Month</option>
                </select>
                {formErrors.time && (
                  <p className="error-text">{formErrors.time}</p>
                )}

                <label htmlFor="message">Additional Message:</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="Tell us more about your project"
                  required
                ></textarea>
                {formErrors.message && (
                  <p className="error-text">{formErrors.message}</p>
                )}

                <div className="modal-actions">
                  <button type="submit" className="btn-primary">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn-secondary"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}

      {showSuccessModal && (
        <div className="success-overlay">
          <div className="success-content">
            <h2>Success!</h2>
            <p>🎉 Your quote request has been sent successfully.</p>
            <button onClick={closeSuccessModal} className="btn-primary">
              Close
            </button>
          </div>
        </div>
      )}

      {showErrorModal && (
        <div className="error-overlay">
          <div className="error-content">
            <h2>Oops!</h2>
            <p>Something went wrong. Please try again later.</p>
            <button onClick={closeErrorModal} className="btn-primary">
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Hero;

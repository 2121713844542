import React, { useState, useEffect } from "react";
import "../styles/AboutUs.css";

const AboutUs = React.forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <section className={`about ${isVisible ? "visible" : ""}`} id="about" ref={ref}>
      <div className="particles">
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
      </div>
      <h2>About Us</h2>
      <div className="content">
        <h3>Welcome to Soft Wizzards!</h3>
        <p>
          We are a team of passionate developers and consultants dedicated to crafting exceptional digital experiences.
          With expertise in modern technologies like React, SPFx, SharePoint, and Node.js, we bring your ideas to life.
        </p>

        <h3>Who We Are</h3>
        <p>
          A team of passionate developers and consultants committed to delivering top-notch solutions.
          Experts in cutting-edge technologies like React, SPFx, SharePoint, and Node.js.
        </p>

        <h3>What We Do</h3>
        <p>
          Transform your ideas into impactful digital solutions tailored to your unique needs.
          Deliver innovative, high-quality results that exceed expectations.
        </p>

        <h3>Why Choose Us</h3>
        <p>
          We don’t just create solutions; we build experiences that inspire and empower.
          Our approach is rooted in creativity, integrity, and technical expertise.
        </p>

        <h3>Let’s Work Together!</h3>
        <p>
          Let Soft Wizzards help you achieve your goals and bring your vision to life!
        </p>
      </div>
    </section>
  );
});

export default AboutUs;

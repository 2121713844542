import React, { useState } from "react";
import "../styles/Navbar.css";
import Logo from '../assets/images/logo2.gif';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.classList.toggle("menu-open", !menuOpen); // Toggle body class
  };

  return (
    <nav className="navbar">
      {/* Logo Section */}
      <div className="logo-container">
        <img src={Logo} alt="Logo" className="logo" />
        <span className="brand-name">Soft Wizzards</span>
      </div>

      {/* Hamburger Icon */}
      <div
        className={`hamburger ${menuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Navigation Links */}
      <div className={`nav-links ${menuOpen ? "open" : ""}`}>
        <a href="#home" className="nav-link" onClick={toggleMenu}>
          Home
        </a>
        <a href="#services" className="nav-link" onClick={toggleMenu}>
          Services
        </a>
        <a href="#technologies" className="nav-link" onClick={toggleMenu}>
          Technologies
        </a>
        <a href="#about" className="nav-link" onClick={toggleMenu}>
          About
        </a>
        <a href="#contact" className="nav-link" onClick={toggleMenu}>
          Contact
        </a>
      </div>
    </nav>
  );
};

export default Navbar;

import React, { useState, useEffect } from "react";
import "../styles/ContactUs.css";

const ContactUs = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <section className={`contact ${isVisible ? "visible" : ""}`} id="contact">
      <div className="particles">
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
      </div>
      <h2>Contact Us</h2>
      <div className="contact-details">
        <div className="detail-card">
          <h3>Email</h3>
          <p>connect@softwizzards.com</p>
        </div>
        <div className="detail-card">
          <h3>Phone</h3>
          <p>+91-9355259260</p>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;

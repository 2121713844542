import React, { useState, useEffect } from "react";
import "../styles/Technologies.css";
import PowerAppsLogo from '../assets/images/powerApps.png';
import SeoLogo from '../assets/images/seoLogo.jpg';

const Technologies = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Trigger animation after 100ms

    return () => clearTimeout(timer);
  }, []);

  const techList = [
    {
      name: "SPFx",
      logo: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Microsoft_Office_SharePoint_%282019%E2%80%93present%29.svg",
      description: "Build powerful SharePoint Framework solutions.",
    },
    {
      name: "SharePoint",
      logo: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Microsoft_Office_SharePoint_%282019%E2%80%93present%29.svg",
      description: "Collaborate and manage content efficiently.",
    },
    {
      name: "ReactJS",
      logo: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
      description: "Develop dynamic user interfaces with React.",
    },
    {
      name: "NodeJS",
      logo: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg",
      description: "Build scalable backend applications with Node.",
    },
    {
      name: ".NET Framework",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7d/Microsoft_.NET_logo.svg/1280px-Microsoft_.NET_logo.svg.png",
      description: "Develop versatile applications for Windows and web with .NET Framework.",
    },
    {
      name: "PHP",
      logo: "https://upload.wikimedia.org/wikipedia/commons/2/27/PHP-logo.svg",
      description: "Server-side scripting for web development.",
    },
    {
      name: "Power Apps",
      logo: PowerAppsLogo,
      description: "Create custom business applications without code.",
    },
    {
      name: "Power Automate",
      logo: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Microsoft_Power_Automate.svg",
      description: "Automate workflows and streamline processes.",
    },
    {
      name: "React Native",
      logo: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
      description: "Build cross-platform mobile applications.",
    },
    {
      name: "SEO",
      logo: SeoLogo,
      description: "Improve your website's visibility on search engines.",
    }
  ];
  

  return (
    <section className={`technologies ${isVisible ? "visible" : ""}`} id="technologies">
      <h2>Technologies We Use</h2>
      <div className="tech-list">
        {techList.map((tech, index) => (
          <div key={index} className="tech-card">
            <img src={tech.logo} alt={`${tech.name} logo`} className="tech-logo" />
            <h3>{tech.name}</h3>
            <p>{tech.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Technologies;

import React, { useState, useEffect } from "react";
import "../styles/Services.css";

const Services = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger animation on component mount
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Delay for smooth entry

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, []);

  return (
    <section className={`services ${isVisible ? "visible" : ""}`} id="services">
      <div className="particles">
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
      </div>
      <h2>Our Services</h2>
      <div className={`service fade-in`}>
        <h3>Web Development</h3>
        <p>Building modern, responsive websites.</p>
      </div>
      <div className={`service fade-in`}>
        <h3>Mobile App Development</h3>
        <p>Creating seamless and intuitive mobile applications.</p>
      </div>
      <div className={`service fade-in`}>
        <h3>Cloud Computing</h3>
        <p>Scalable and secure cloud-based solutions.</p>
      </div>
      <div className={`service fade-in`}>
        <h3>Search Engine Optimization</h3>
        <p>Improving your website's visibility on search engines.</p>
      </div>
      <div className={`service fade-in`}>
        <h3>Social Media Marketing</h3>
        <p>Boosting your brand presence on social media platforms.</p>
      </div>
      <div className={`service fade-in`}>
        <h3>Hosting Registration</h3>
        <p>Reliable and fast hosting services for your website.</p>
      </div>
      <div className={`service fade-in`}>
        <h3>Domain Registration</h3>
        <p>Secure and manage your domain name with ease.</p>
      </div>
    </section>
  );
};

export default Services;
